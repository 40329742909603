<script>
import Multiselect from "vue-multiselect";

import {
} from "@/state/helpers";

export default {
  data() {
    return {
      types: [
        {id: "text", label: this.$t('search_list.text')},
        {id: "image", label: this.$t('search_list.image')},
        {id: "voice", label: this.$t('search_list.voice') },
      ],

      autocomplete: [
        {id: "TRUE", label: this.$t('search_list.with_autocomplete')},
        {id: "FALSE", label: this.$t('search_list.without_autocomplete')},
        
      ],

      auxAutocomplete: null,
      auxType: null,
      filters:[]
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    
    removeAll(){
      this.auxType = null;
      
    },

    getAllFilters(){
      const filters= [];
      if(this.auxType){
        filters.push({
          key: 'type',
          value: this.auxType.id,
          badge: `${this.$t('search_list.type')}: ${this.auxType.label}`
        });
      }

      if(this.auxAutocomplete){
        filters.push({
          key: 'autocomplete',
          value: this.auxAutocomplete.id,
          badge: `${this.$t('search_list.autocomplete')}: ${this.auxAutocomplete.label}`
        });
      }
      return filters;
    },

    setAllFilter(filters){
      this.auxType = filters.filter(f=> f.key== 'type').length > 0 ? this.types.filter(g=> g.id == filters.filter(f=> f.key== 'type')[0].value)[0]  :null
      this.auxAutocomplete = filters.filter(f=> f.key== 'autocomplete').length > 0 ? this.types.filter(g=> g.id == filters.filter(f=> f.key== 'autocomplete')[0].value)[0]  :null
      this.filters = filters;
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="auxType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('search_list.type')}}: {{auxType?.label}}</span>
              <i v-on:click="auxType =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxAutocomplete">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('search_list.autocomplete')}}: {{auxAutocomplete?.label}}</span>
              <i v-on:click="auxAutocomplete =null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    
    <div class="mb-2">
      <div v-b-toggle="'accordion-0'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('search_list.type')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-0`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t('search_list.type_subtitle')}}</p>
          <multiselect 
            :options="types"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxType"
            :allow-empty="false">
          </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2" v-if="false">
        <div v-b-toggle="'accordion-1'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('search_list.autocomplete')}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-1`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('search_list.autocomplete_subtitle') }}</p>
            <multiselect 
              :options="autocomplete"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="id" 
              label="label"
              :show-labels="false"
              :multiple="false"
              v-model="auxAutocomplete"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
  </div>
</template>
