<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import helpers from '../../../../helpers'
import EmptyList from "@/components/widgets/empty_list"
import FilterC from "@/components/search/filter"
import Drawer from "vue-simple-drawer";

import {
	searchAnalyticsMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Search",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
		Layout,
		DatePicker,
		EmptyList,
		FilterC,
		Drawer
  },
  data() {
	return {
		showModalHost: false,
		showModalProducts:false,
		isLoading: false,
		fullPage: true,
		canCancel: false,
		useSlot: false,
		color: "#007bff",
		bgColor: "#ffffff",
		height: 128,
		width: 128,
		lastSyncDate: '-',
		searches:[],
		currentPage:1,
		perPage:15,
		rows:0,
		query: '',
        showModalSearchResult : false,
		showImageModal:false,
        items:[],
		imageUrl: '',
		disabledBefore: new Date().setDate(new Date().getDate() - 90),
		dateFilter: null,
    shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],
			empty_config:{
					"title" : this.$t('search_list.empty_title'),
					"subtitle" : this.$t('search_list.empty_text'),
					"buttonText" : null,
					icon: "fa-cubes"
			},
			filters:[],
			openRightDrawer: false
	}
  },
  
  mounted() {
		const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 
  },
  created() {
	
  },
  methods: {
	... searchAnalyticsMethods,
	...helpers,
	
	rightDrawer(){
		this.openRightDrawer = false;
	},

	formatDate(d) {
      
			let month = '' + (d.getMonth() + 1);
			let day = '' + d.getDate();
			const year = d.getFullYear();
	
			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;
			
			return [year, month, day].join('-');
		},
		getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          q+= `&${f.key}=${f.value}`
        });
      }

      return q;
    },
	applyFilter(query){

		this.isLoading = true;
		let loader = this.$loading.show();
		if(this.filters.length > 0){
			query+= this.getCustomFilterQuery();
		}

    this.query = query;
		query+=`&limit=${this.perPage}`;

		this.currentPage = 1;
		this.getSearchesList(query).then((res)=>{
			this.searches = res.searches || [];
			if(res.total && res.total.length > 0){
				this.rows = res.total[0].total;
			}
			
		}).catch(()=>{
			this.$notify({ type: 'error', text: this.$t('search_list.get_searches_error'),title:  this.$t('search_list.title') });
		}).finally(()=>{
			this.isLoading = false;
			loader.hide();
		});

	},

	loadSearchesWithPaging(){
		this.isLoading = true;
		let loader = this.$loading.show();
		const current = this.currentPage == 0 ? this.currentPage : this.currentPage - 1;
		const query = `${this.query}&limit=${this.perPage}&skip=${current * this.perPage}`

		this.getSearchesList(query).then((res)=>{
			this.searches = res.searches || [];
			if(res.total && res.total.length > 0){
				this.rows = res.total[0].total;
			}
			
		}).catch(()=>{
			this.$notify({ type: 'error', text: this.$t('search_list.get_searches_error'),title:  this.$t('search_list.title') });
		}).finally(()=>{
			this.isLoading = false;
			loader.hide();
		});

	},  
	onSearchesListPageClicked(){
		this.loadSearchesWithPaging();
	},

	onImageClicked(imageurl){
		if(imageurl){
			this.showImageModal = true;
			this.imageUrl= imageurl;
		}
	},
	
    onResultsClicked(search){
			let loader = this.$loading.show();

			const query = `project=${search.project}&items=${search.document.results.toString()}`
			this.getResultsBySearch(query).then((res)=>{
					this.items = res.items;
					this.showModalSearchResult = true;
			
		}).catch(()=>{
			this.$notify({ type: 'error', text: this.$t('search_list.get_search_results_error'),title:  this.$t('search_list.title') });
		}).finally(()=>{
			loader.hide();
		});
    },
		onFilterClicked(){
			this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
		},
		onAnalyzeClicked(){
			this.$router.push(
				{
					path: "/search-analyze",
				}
			).catch(() => {});
		},
		removeAllFilter(){
      this.$refs.filterRef.removeAll();
    },
		applyCustomFilter(){
			this.filters = this.$refs.filterRef.getAllFilters();
			const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

			let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

			this.applyFilter(query);
			this.openRightDrawer = false;
		},
		onRemoveFilter(filter){
			this.filters = this.filters.filter(f=> f.key != filter.key);

			const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

			let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

			this.applyFilter(query)
		}
	},
	watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
	<Layout>
		<div class="col-lg-12 mb-3">
			<div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-2">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('search_list.title')}}</h5>
            <p class="text-muted mb-0">{{ $t('search_list.subtitle') }}</p>
          </div>
					<div class="text-end w-50">
					<div class="row align-items-center justify-content-end">
						<div class="col-auto ps-0 w-50">
							<div class="flex">
								<date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range
									:format="'DD.MM.YYYY'" :clearable="false"
									:disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
								</date-picker>
							</div> 
						</div>
						<div class="col-auto ms-0 ps-0">
                <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="primary">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-white"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onFilterClicked">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
										<div class="dropdown-divider"></div>
										<a class="dropdown-item" href="javascript: void(0);" v-on:click="onAnalyzeClicked">
                      <i class="mdi mdi-chart-line"></i>
                      {{ $t('menu.search_analyze') }}
                    </a>
										<div class="dropdown-divider mb-0"></div>
                  </b-dropdown>
              </div>
					</div>
				</div>
			</div>
			<div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{ f.badge }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
        </div>
		</div>
		
		<div class="row">
			<div class="col-12">
				<div class="card mb-3">
					<div class="card-body p-0">
						<EmptyList :config="empty_config" v-if="!isLoading && searches.length == 0 " class="mt-3 mb-3"/>
						<div class="row mb-0" v-if="!isLoading && searches.length > 0">
							<div class="table-responsive">
								<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
										<thead class="table-light">
											<tr>
												<th scope="col">{{$t('search_list.date')}}</th>
												<th scope="col">{{$t('search_list.text_or_image')}}</th>
												<th scope="col">{{$t('search_list.type')}}</th>
												<th scope="col"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="search in searches" :key="search.id">
												<td>{{ `${parseDateWithLocale(search.date, {year: 'numeric', month: 'long', day: 'numeric'})} ${parseTimeWithLocale(search.date, {hour: '2-digit', minute:'2-digit'})}`}}</td>
												<td v-if="search.type && search.type=='image'"><i class= 'bx bx-camera' v-on:click="onImageClicked(search.imageurl)"/></td>
												<td v-if="search.type == null || search.type=='text' || search.type=='voice'">{{search.text}}</td>
                         <td>
													<span class="badge font-size-10 bg-primary" v-if="search.type && search.type=='image'">
															<i class= 'bx bx-camera'/> {{$t('search_list.image')}}
													</span>
													<span class="badge font-size-10 bg-primary" v-if="search.type == null || search.type=='text'">
															<i class= 'bx bx-pencil'/> {{$t('search_list.text')}}
													</span>
													<span class="badge font-size-10 bg-primary" v-if="search.type=='voice'">
															<i class= 'bx bx-microphone'/> {{$t('search_list.voice')}}
													</span>
												</td>
                                                
                         <td class="text-end">
														<i class= 'bx bx-show interact' v-on:click="onResultsClicked(search)" v-if="search.document.results && search.document.results.length > 0" />
														<span class="badge font-size-10 bg-warning" v-if="search.document.results == null || search.document.results.length ==0">
																{{$t('search_list.without_results')}}
														</span>
												</td>
											</tr>
										</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-3 pe-0" v-if=" !isLoading && rows > perPage">
					<div class="col pe-0">
						<div class="dataTables_paginate paging_simple_numbers float-end">
							<ul class="pagination pagination-rounded mb-0">
								<b-pagination
									@input="onSearchesListPageClicked"
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage">
								</b-pagination>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<b-modal
				v-model="showModalSearchResult"
				:title="$t('search_list.results')"
				title-class="text-black font-18"
				body-class="p-3"
				hide-footer
				scrollable>
				<div v-for="item in items" :key="item.id" class="d-flex mt-2 mb-2">
									<img v-if="item.document.data.imgs.length > 0" :src="item.document.data.imgs[0].url" height="50px" style="object-fit: contain; margin-right: 10px;"/>
									<p class="centered-element"> {{ item.document.data.name }}</p>
							</div>
			</b-modal>
			<b-modal v-model="showImageModal" id="modal-center" hide-footer hide-head title-class="font-18">
          <img :src="imageUrl" width="100px" class="text-center"/>
      </b-modal>
			<Drawer
      @close="rightDrawer"
      :align="'right'"
      :closeable="true"
      :maskClosable="true"
      :zIndex="1002"
      class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                  {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <FilterC ref="filterRef"></FilterC>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
    </Drawer>
	</Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}

.centered-element {
  margin: auto 0;
}
</style>

<style>
.dropdown-toggle {
  padding-top: 5px !important;
}
</style>